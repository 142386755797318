<template>
  <div>
    <v-row>
      <v-col cols="12" md="10" lg="8">
        <MacotaForm
          :currentStep="currentStep"
          @ChangeOnlyStep="ChangeOnlyStep"
        ></MacotaForm>
        <ClienteForm
          @goBack="goBackStep"
          :currentStep="currentStep"
          @ChangeOnlyStep="ChangeOnlyStep"
        ></ClienteForm>
        <ConfirmData
          @goBack="goBackStep"
          @goToPay="createEmisionAndReferenciaPago"
          :finalData="finalData"
          :currentStep="currentStep"
        >
        </ConfirmData>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <MascotaEmisionDetails> </MascotaEmisionDetails>
      </v-col>
      <div style="display: none;">
        <form :action="URL_BASE_PAYMENT" method="POST" id="mediForm">
          <input name="AppProduct" id="AppProduct" v-model="AppProduct" />
          <input name="Referencia" id="Referencia" v-model="reference" />
          <input name="HASH" id="HASH" v-model="hash" />
        </form>
      </div>
    </v-row>
    <commond-loading
      :show="loading"
      loadingText="Cargando..."
      :loadingImage="dataImage.iconPet"
    />
  </div>
</template>

<script>
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";
import {
  createEmisionAndReferenciaPago,
  getQuattroCodificacionAPI,
} from "@/services/mascota/mascota.service";
import MacotaForm from "./MacotaForm.vue";
import MascotaEmisionDetails from "./MascotaEmisionDetails.vue";
import ClienteForm from "./ClienteForm.vue";
import ConfirmData from "./ConfirmData.vue";
import moment from "moment";
import dataMascota from "@/helpers/data/data-loading-gif.json";
//import CryptoJS from "crypto-js";

export default {
  components: {
    MacotaForm,
    MascotaEmisionDetails,
    ClienteForm,
    ConfirmData,
    CommondLoading: () =>
      import("@/components/commonComponents/CommondLoading.vue"),
  },

  data() {
    return {
      logo:
        "https://todoseguros.com.mx/wp-content/uploads/2021/06/Seguros-GMX.jpg",
      cotizacionMascotaDetail: {},
      selectedCotizacion: "",
      responseAseguradora: [],
      planes: [],
      currentStep: 1,
      firstStepData: {},
      secondStepData: {},
      finalData: {},
      loading: false,
      dataImage: dataMascota,
      reponseEmision: {},
      hash: "",
      reference: "",
      AppProduct: process.env.VUE_APP_APP_PRODUCT,
      URL_BASE_PAYMENT: "",
    };
  },

  methods: {
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatoMonedaMXN.format(val);
    },
    async getCotizacion() {
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped: true,
        cotizacion_general: this.$route.params.cotizacionGeneral,
      });
      this.planes = JSON.parse(this.responseAseguradora[0].response);
    },
    ChangeOnlyStep(toStep, data) {
      this.currentStep = toStep;
      if (data != null)
        switch (toStep) {
          case 2:
            this.firstStepData = JSON.parse(JSON.stringify(data));
            break;
          case 3:
            this.secondStepData = JSON.parse(JSON.stringify(data));
            this.goToCreateEmission();
            break;
          case 4:
            this.thirdStepData = JSON.parse(JSON.stringify(data));
            break;
        }
    },
    goToCreateEmission() {
      this.finalData = {
        etapa: "1",
        subEtapa: "1",
        pantalla: "4",
        correo: "hola@inter.mx",
        numeroCotizacion: this.$route.params.cotizacionGeneral,
        ...Object.assign({}, this.secondStepData),
        ...{
          plan: {
            producto: this.currentCotizacion.ProductoCodigo,
            codigo: this.currentCotizacion.PlanCodigo,
            nombre: this.currentCotizacion.PlanNombreInter,
            precio: this.currentCotizacion.PlanPrimaTotal,
          },
        },
        ...{ mascota: [this.firstStepData] },
      };
      this.finalData.datosContratante.fechaNacimiento = moment(
        this.finalData.datosContratante.fechaNacimiento
      ).format("DD/MM/YYYY");
      this.finalData.datosPagador.fechaNacimiento = moment(
        this.finalData.datosPagador.fechaNacimiento
      ).format("DD/MM/YYYY");
    },
    async createEmisionAndReferenciaPago() {
      this.loading = true;
      for (let i = 0; i < 5; i++) {
        try {
          this.reponseEmision = await createEmisionAndReferenciaPago(
            this.finalData
          );
          if (this.validateResponse()) {
            return; // Salir de la función si la respuesta es válida
          } else if (i === 4) {
            // Si es el último intento y la respuesta sigue siendo inválida
            this.$toast.error(
              "Error al obtener la referencia de pago por favor valida los datos"
            );
            this.loading = false;
          }
        } catch {
          if (i === 4) {
            // Si es el último intento y la petición falla
            this.$toast.error(
              "Error al obtener la referencia de pago por favor valida los datos"
            );
            this.loading = false;
          }
        }
      }
    },

    validateResponse() {
      const responseGMX = JSON.parse(this.reponseEmision.emision);
      if (
        responseGMX.data.Referencia["0"] &&
        responseGMX.data.Referencia["0"] != null &&
        responseGMX.data.Referencia["0"] != ""
      ) {
        this.encriptarReferencia(responseGMX.data.Referencia[0]);
        this.reference = responseGMX.data.Referencia[0];
        this.$toast.success(
          "Referencia de pago obtenida..",
          responseGMX.data.Referencia["0"]
        );
        return true;
      } else {
        this.$toast.error(
          "Error con la aseguradora para obtener la liga de pago"
        );
        return false;
      }
    },

    async encriptarReferencia(reference) {
      let dominio = window.location.origin;
      let url_retorno =
        dominio +
        `/mascota/pago/details/${this.$route.params.cotizacionGeneral}/${this.reponseEmision.emisionGenerated.id}`;

      if (reference !== "") {
        let params = [
          { message: process.env.VUE_APP_APP_PRODUCT + reference },
          { opcion: 1 },
          { secret: process.env.VUE_APP_MASCOTA_KEY },
          { referencia: reference },
          { retorno: url_retorno },
        ];
        try {
          let res = await getQuattroCodificacionAPI(params);
          if (res.success) {
            this.hash = res.response;
            setTimeout(() => {
              let myForm = document.getElementById("mediForm");
              myForm.submit();
            }, 1000);
            this.loading = false;
          }
        } catch {
          this.$toast.error("Error al encriptar la referencia de pago.");
          this.loading = false;
          return false;
        }
        this.loading = false;
      }
    },
    goBackStep(toStep) {
      this.currentStep = toStep;
    },
  },
  computed: {
    currentCotizacion() {
      var coberturas = [];
      if (this.planes.length > 0 && this.selectedCotizacion) {
        coberturas = this.planes.find(
          (e) => e.PlanCodigo == this.selectedCotizacion
        );
      }
      return coberturas;
    },
  },
  mounted() {
    this.selectedCotizacion = this.$route.params.planID;
    this.getCotizacion();
    //CAMBIAR LA URL PARA PASE A PRODUCCION
    this.URL_BASE_PAYMENT = process.env.VUE_APP_URL_BASE_PAYMENT_MASCOTA_QA;
  },
};
</script>
